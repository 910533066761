import styles from './styles.module.css';

const PISO3NOTASLoMasVisto = (props) => {
console.log(props);
    return (
          
        <div className={`${styles["Piso3Notas-parent"]}`}>
            <div className={`${styles['primer-bloque']}`}>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(1, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(2, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(3, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(4, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(5, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(6, "NotaContainerGrilla")}</div>
            </div>
            <div className={`${styles['segundo-bloque']}`}>
                <div className={styles['grid-item2']}>{props.renderContainerWidgetCallback(7, "LoMasVisto")}</div>
            </div>                
        </div>
        
    )
}

export default PISO3NOTASLoMasVisto;
